import React from 'react';
import './App.css';
import bannerImage from './img/marylebonebanneart.png';
import logo from './img/md-logo.png'; // Import the logo

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={bannerImage} alt="Marylebone Banner" className="banner-image" />
        <div className="fade-out"></div>
      </header>
      <main>
        <img src={logo} alt="Marylebone Design Logo" className="logo" /> {/* Display the logo */}
        <p>
          Welcome to Marylebone Design, where creativity meets innovation. 
          We specialize in modern, sustainable AI design solutions.
        </p>
        <button className="cta-button">Contact Us</button>
      </main>
    </div>
  );
}

export default App;

